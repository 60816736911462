<template>
    <v-dialog v-model="dialog" 
    >
        <template v-slot:activator="{on,attrs}">
          
        <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
            @click="get_sessoes()"
        >   
            <v-icon medium class="mr-2" color="grey">mdi-plus</v-icon>
        </v-btn>
        
        </template>
        <v-card>
            <v-card-actions class="justify-end">
                <v-btn text @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-actions>
            <MyCardTitle modulo="Detalhamento das Evoluções e Coleta de Assinaturas" :card-info="currentItem" />
            <v-card-text>
                <!-- falta colocar para puxar a data de nascimento -->
                <p><strong>Paciente: {{currentItem.paciente.nome}} -->  Data de nascimento: {{ currentItem.paciente.data_nascimento | formatDatabr }}</strong></p>
                <p>Procedimento: {{currentItem.procedimento.nome}} --> Período: {{currentItem.data_inicio | formatDatabr }} a {{currentItem.data_fim | formatDatabr }}</p> 
                <p>Quantidade Autorizada: {{currentItem.qtd}} --> Frequência: {{currentItem.frequencia.nome}}</p>
                <p>Senha: {{currentItem.senha }} --> Data Início: {{ currentItem.senha_data_inicio | formatDatabr }} --> Data Validade {{ currentItem.senha_data_validade | formatDatabr }}</p>
                <!--
                    <pre>{{currentItem}}</pre>
                -->
                <pre>
                    {{ currentItem.procedimento }}
                </pre>
            </v-card-text>
            <v-card-text>
                <v-data-table
                    :items="sessoes"
                    :headers="headers"
                >
                
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '@/http'
export default {
    name:"AddAtendimento",
    components:{
        MyCardTitle: ()=>import('../../components/uteis/card_title.vue')
    },
    props:{
        currentItem:{
            type:Object
        }
    },
    data:() => ({
        sessoes:[],
        headers:[
            {text:"Id", align: "start", sortable: true, value: "id"},
            {text:"Procedimento", align: "start", sortable: true, value: "procedimento.nome" },
            {text:"Profissional", align: "start", sortable: true, value: "equipe" },
            {text:"Data da Evolução", align: "start", sortable: true, value: "" },
            {text:"Data da Coleta Assiantura", align: "start", sortable: true, value: "" },
        ],
        dialog: false,
    }),
    methods:{
        get_sessoes(){
            api(`atendimentos/evolucoes/?sessao=${this.currentItem.id}`)
            .then((e)=>{
                this.sessoes = e.data
            })
        }
    },
    mounted(){
    }
}
</script>